import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BigHeader from "../components/bigHeader"

const Page = ({ pageContext }) => {
  const { title, content, image } = pageContext
  const seo = pageContext.seo
  const lang = pageContext.data.language.slug

  return (
    <Layout language={pageContext.data.language} lang={lang}>
      <SEO title={seo.title} description={seo.metaDesc} />

      <BigHeader title={title} image={image} />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </Layout>
  )
}

export default Page
